import React, { useEffect, useState } from 'react';

const SerialPortListComponent = () => {
    const [ports, setPorts] = useState([]);
    const [error, setError] = useState(null);
    const [data, setData] = useState('');
    const [sendData, setSendData] = useState('');
    const [selectedPort, setSelectedPort] = useState(null);

    useEffect(() => {
        // Seri portları listele
        window.electron
            .invoke('list-serial-ports')
            .then((response) => {
                if (response.error) {
                    setError(response.error);
                } else {
                    setPorts(response);
                }
            })
            .catch((error) => setError('Error fetching serial ports'));
    }, []);

    // Seri porttan gelen veriyi dinle
    useEffect(() => {
        const removeListener = window.electron.on('serial-port-data', (event, serialData) => {
            setData(serialData); // Seri porttan gelen veriyi ekrana yazdır
        });

        // Cleanup: bileşen unmount olduğunda dinleyiciyi kaldır
        return () => {
            removeListener();
        };
    }, []);

    const connectToPort = (portPath) => {
        window.electron
            .invoke('connect-serial-port', portPath)
            .then((response) => {
                if (response.error) {
                    setError(response.error);
                } else {
                    setSelectedPort(portPath); // Store selected port path
                    console.log(response.success);
                }
            })
            .catch((error) => setError('Error connecting to serial port'));
    };

    const sendSerialData = () => {
        if (selectedPort && sendData) {
            window.electron
                .invoke('send-serial-data', { port: selectedPort, data: sendData })
                .then((response) => {
                    if (response.error) {
                        setError(response.error);
                    } else {
                        console.log('Data sent successfully');
                    }
                })
                .catch((error) => setError('Error sending data'));
        } else {
            setError('No port selected or no data to send');
        }
    };

    return (
        <div>
            <h1>Available Serial Ports</h1>
            {error ? (
                <div style={{color: 'red'}}>{error}</div>
            ) : (
                <ul>
                    {ports.length > 0 ? (
                        ports.map((port) => (
                            <li key={port.path}>
                                {port.path} - {port.manufacturer || 'Unknown Manufacturer'}
                                <button onClick={() => connectToPort(port.path)}>
                                    Connect
                                </button>
                            </li>
                        ))
                    ) : (
                        <li>No serial ports found</li>
                    )}
                </ul>
            )}
            {selectedPort && (
                <div>
                    <h2>Send Data</h2>
                    <input
                        type="text"
                        value={sendData}
                        onChange={(e) => setSendData(e.target.value)}
                        placeholder="Enter data to send"
                    />
                    <button onClick={sendSerialData}>Send Data</button>
                </div>
            )}
            {data && (
                <div>
                    <h2>Received Data</h2>
                    <p>{data}</p>
                </div>
            )}
        </div>
    );
};

export default SerialPortListComponent;
