import {createBrowserRouter, createRoutesFromElements, defer, Route} from "react-router-dom";
import ProtectedLayout from "./layout/ProtectedLayout";
import DashboardPage from "./pages/home/DashboardPage";
import LoginPage from "./pages/auth/LoginPage";
import AuthLayout from "./layout/AuthLayout";
import "../src/styles/Style.sass"

const getUserData = () =>
    new Promise((resolve) =>
        setTimeout(() => {
          const user = window.localStorage.getItem("user");
          resolve(user);
        }, 1000)
    );

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<AuthLayout />}
            loader={() => defer({ userPromise: getUserData() })}
        >

          <Route>
            {/*<Route path={"/"} element={<LoginPage></LoginPage>}></Route>*/}
            <Route path={"/login"} element={<LoginPage></LoginPage>}></Route>
          </Route>

            <Route path={"/"} element={<ProtectedLayout/>}>
              <Route path={"/"} element={<DashboardPage></DashboardPage>}></Route>
          </Route>

          <Route path="/dashboard" element={<ProtectedLayout />}>
            <Route path="/dashboard" element={<DashboardPage />} />
          </Route>

        </Route>
    )
)