import React from 'react';
import ReactDOM from 'react-dom/client';
import trTR from 'antd/locale/tr_TR';
import {ConfigProvider} from "antd";
import { RouterProvider } from "react-router-dom";
import {router} from "./App";
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
dayjs.locale('tr');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ConfigProvider
        locale={trTR}
        theme={{
            hashed: false,
            token: {
                colorPrimary: '#257b55',
            },
        }}
    >

        <RouterProvider router={router} />
    </ConfigProvider>
);