import React from 'react';
import { createContext, useEffect, useState } from 'react';

const SerialPortContext = createContext();

const SerialPortContextProvider = ({ children }) => {
    const [port, setPort] = useState(null);
    const [reader, setReader] = useState(null);
    const [output, setOutput] = useState('');
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        // Cleanup function to close the port and release the reader when the component unmounts
        return () => {
            if (reader) {
                reader.cancel().catch(() => {});
            }
            if (port) {
                port.close().catch(() => {});
            }
        };
    }, [reader, port]);

    const connectPort = async () => {
        try {
            // Request the user to select a serial port
            const newPort = await navigator.serial.requestPort();

            // Open the selected port with a baud rate
            await newPort.open({ baudRate: 9600 });
            setPort(newPort);
            setConnected(true);

            // Get a reader to read data from the port
            const newReader = newPort.readable.getReader();
            setReader(newReader);
            readLoop(newReader).then(r => {});
        } catch (error) {
            console.error('Error connecting to port:', error);
        }
    };

    const disconnectPort = async () => {
        try {
            if (reader) {
                reader.cancel().catch(() => {});
            }
            if (port) {
                port.close().catch(() => {});
            }
            setPort(null);
            setReader(null);
            setConnected(false);
        } catch (error) {
            console.error('Error disconnecting from port:', error);
        }
    }

    const status = async () => {
        try {
            const ports = await navigator.serial.getPorts();
            return ports;
        } catch (error) {
            console.error('Error getting port info:', error);
            return -1;
        }
    }

    const checkConnection = async () => {
        const isConnected = await status();
        if (isConnected) {
            console.log('Port bağlı.');
        } else {
            console.log('Port bağlı değil.');
        }
    };

    const readLoop = async (reader) => {
        const decoder = new TextDecoder();
        while (true) {
            try {
                const { value, done } = await reader.read();
                if (done) {
                    break;
                }
                setOutput((prev) => prev + decoder.decode(value));
            } catch (error) {
                console.error('Error reading from port:', error);
                break;
            }
        }
    };

    return (
        <SerialPortContext.Provider value={{
            port,
            reader,
            output,
            connected,
            status,
            disconnectPort,
            connectPort,
            checkConnection
        }}>
            {/*<button onClick={connectPort}>Connect to Serial Port</button>*/}
            {children}
        </SerialPortContext.Provider>
    );
};

export {SerialPortContextProvider, SerialPortContext};