import {Button, Col, message, Modal, Popconfirm, Row, Space, Table, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {DeleteOutlined, ExclamationCircleOutlined, FormOutlined} from "@ant-design/icons";
import Column from "antd/es/table/Column";
import {useContext, useEffect, useRef, useState} from "react";
import Api from "../../services/Api";
import axios from "axios";
import dayjs from "dayjs";
import {SerialPortContext} from "../../context/SerialPortContext";
import SerialPortListComponent from "../../utils/SerialPortListComponent";

const DashboardPage = () => {
    const [tarifeler, setTarifeler] = useState([])
    const [countText, setCountText] = useState("0")
    const [satis, setSatis] = useState([]);
    const [orderConfirmModal, orderContextHolder] = Modal.useModal();
    const [gerceklesenSatislar, setGerceklesenSatislar] = useState([])

    // useEffect(() => {
    //     getTarifeler()
    //     getGerceklesenSatislar()
    // }, []);

    const getTarifeler = () => {
        Api.get("api/Tarifes?PageIndex=0&PageSize=100").then(res => {
            setTarifeler(res.data.items)
        }).catch(err => {
            message.error("Tarifeler getirilemedi")
        })
    }

    const numberClick = (number) => {
        if(countText.length > 2){
            message.error("En fazla 3 haneli olabilir")
            return;
        }
        //sıfır var ise sil ve yan yana ekle
        if(countText === "0"){
            setCountText(number)
        }

        //sıfır yok ise yanına ekle
        else{
            setCountText(countText +""+ number)
        }
    }

    const deleteNumber = () => {
        if(countText.length === 1){
            setCountText("0")
        }
        else{
            setCountText(countText.substring(0,countText.length-1))
        }
    }

    const addLine = (item) => {
        //eğer aynı id'den varsa adetini arttır
        let isExist = satis.find(x => x.id === item.id)

        if(isExist){
            let index = satis.findIndex(x => x.id === item.id)
            let newSatis = {
                id: item.id,
                tarifeKodu: item.tarifeKodu,
                tarifeAdi: item.tarifeAdi,
                tarifeTutar: item.tarifeTutar,
                adet: parseInt(satis[index].adet) + parseInt(countText === "0" ? 1 : countText),
                satirTutar: (item.tarifeTutar * (parseInt(satis[index].adet) + parseInt(countText === "0" ? 1 : countText)))
            }
            satis[index] = newSatis
            setSatis([...satis])
            setCountText("0")
            return;
        }

        let newSatis = {
            id: item.id,
            tarifeAdi: item.tarifeAdi,
            tarifeTutar: item.tarifeTutar,
            tarifeKodu: item.tarifeKodu,
            adet: countText === "0" ? 1 : countText,
            satirTutar: (item.tarifeTutar * (countText==="0"? 1 : countText))
        }

        setSatis([...satis, newSatis])
        setCountText("0")
    }

    const deleteLine = (id) => {
        let newSatis = satis.filter(x => x.id !== id)
        setSatis(newSatis)
    }

    const totalMoney = () => {
        let total = 0;
        satis.map((item) => {
            total += item.satirTutar
        })
        return total;
    }

    const countAzalt = () => {
        if(countText === "0"){
            return;
        }
        setCountText(parseInt(countText) - 1)
    }

    const countArttir = () => {

        if(countText === "0")
            return;

        setCountText(parseInt(countText) + 1)
    }

    const confirmOrder = () => {
        orderConfirmModal.confirm({
            title: 'Satışı Onayla',
            icon: <ExclamationCircleOutlined />,
            content: 'İşlemi onaylamak istediğinize emin misiniz?',
            okText: 'Evet',
            onOk: () => {islemiTamamla()},
            centered: true,
            cancelText: 'Hayır',
        });
    }

    const kapiAc = (sayi) => {
        axios.get("http://192.168.1.99:5000/api/pass?count="+sayi).then(res => {
            message.success("Turnikiye gönderildi")
        }).catch(err => {
            message.error("Turnikiye gönderilemedi")
        })
    }

    const islemiTamamla = () => {

        if(satis.length === 0){
            message.error("Satış yapılacak ürün bulunamadı").then(r => {})
            return;
        }

        const data = {
            islemYapan:"Sistem",
            satis:satis
        }

        Api.post("/api/Satis", data).then(res => {
            message.success(res.data.message)
            setSatis([])

            getGerceklesenSatislar()

            const toplamAdet = satis.reduce((a, b) => a + parseInt(b.adet), 0)
            kapiAc(toplamAdet)

        }).catch(err => {
            message.error("Satış işlemi gerçekleştirilemedi")
        })

    }

    const getGerceklesenSatislar = () => {



        const query = {
            "sort": [
                {
                    "field": "createdDate",
                    "dir": "desc"
                }
            ],
            "filter": {
                "field": "CreatedDate.Date",
                "operator": "eq",
                "value": `${dayjs(new Date()).format("YYYY-MM-DD")}`,
                "logic": "and"
            }
        }


        Api.post("/api/Satis/GetList/ByDynamic?PageIndex=0&PageSize=10000", query).then(res => {
            res.data.items.map((item) => {
                let total = 0;
                let detail= "";
                item.satisDetay.map((satis) => {
                    total += satis.satirTutar
                    detail += satis.tarifeAdi + " " + satis.tarifeAdet + " Kişi, "
                })
                item.toplamTutar = parseInt(total.toFixed(2))
                item.detail = detail
            })

            setGerceklesenSatislar(res.data.items)

        }).catch(err => {
            console.log(err)
            message.error("Gerçekleşen satışlar getirilemedi")
        })
    }



    return(
        <>
            <SerialPortListComponent></SerialPortListComponent>
            <div className={"buying-content"}>
                <div className={"buying-grid"}>
                    <div className={"buy-total-screen"}>
                        <div className={"text"}>Toplam Tutar</div>
                        <div className={"value"}>{totalMoney().toFixed(2)} TL</div>
                    </div>
                    <div className={"buying-list"}>
                        <div className={"table-card"}>
                            <Table rowKey={"id"} dataSource={satis} size={"small"} className={"h-100"} pagination={true}>
                                <Column title={""} width={40} dataIndex={""} render={(_, record) => {
                                    return(
                                        <Space>
                                            <Button onClick={() => deleteLine(record.id)} size={"small"} icon={<DeleteOutlined />} ></Button>

                                        </Space>
                                    )
                                }}></Column>
                                <Column title={"Tarife"} className={"font-weight-bold"} dataIndex={"tarifeAdi"}></Column>
                                <Column title={"Birim Fiyatı"} align={"right"} dataIndex={"tarifeTutar"}
                                        render={(_,record) => {
                                            return record.tarifeTutar.toFixed(2) + " TL"
                                        }}>
                                </Column>
                                <Column title={`Adet`} align={"right"} dataIndex={"adet"} render={(_, record) => {
                                    return record.adet+" Kişi"
                                }}></Column>
                                <Column title={"Satır Toplam"} className={"font-weight-bold"} align={"right"} dataIndex={"satirTutar"}
                                        render={(_,record) => {
                                            return record.satirTutar.toFixed(2) + " TL"
                                        }}
                                ></Column>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className={"buying-order"}>
                    {orderContextHolder}
                    <div onClick={() => confirmOrder()} className={"order-success"}>
                        İŞLEMİ TAMAMLA
                    </div>
                    <div className={"number-label"}>
                        <input readOnly={true} value={countText}></input>
                    </div>
                    <div className={"number-section"}>
                        <div className={"buttons"}>
                            <div onClick={() => numberClick(1)} className={"number-button"}>1</div>
                            <div onClick={() => numberClick(2)} className={"number-button"}>2</div>
                            <div onClick={() => numberClick(3)} className={"number-button"}>3</div>
                            <div onClick={() => numberClick(4)} className={"number-button"}>4</div>
                            <div onClick={() => numberClick(5)} className={"number-button"}>5</div>
                            <div onClick={() => numberClick(6)} className={"number-button"}>6</div>
                            <div onClick={() => numberClick(7)} className={"number-button"}>7</div>
                            <div onClick={() => numberClick(8)} className={"number-button"}>8</div>
                            <div onClick={() => numberClick(9)} className={"number-button"}>9</div>
                            <div onClick={() => numberClick(0)} className={"number-button"}>0</div>
                            <div className={"number-button"} onClick={() => setCountText("0")} style={{fontSize:10}}>TEMİZLE</div>
                        </div>
                        <div className={"right-buttons"}>
                            <div onClick={() => deleteNumber()} style={{fontSize:22}} className={"action-button"}>DÜZELT</div>
                            <div onClick={() => countAzalt()} className={"action-button"}>-</div>
                            <div onClick={() => countArttir()} className={"action-button"}>+</div>
                            <div className={"action-button"} style={{fontSize:21}}>SATIR SİL</div>
                        </div>
                    </div>
                </div>
                <div className={"buying-options"}>
                    {tarifeler.map((item, index) => {
                        return(
                            <div onClick={() => addLine(item)} key={index} className={"item"}>
                                <div className={"text"}>{item.tarifeAdi}</div>
                                <div className={"value"}>{item.tarifeTutar} TL</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={"sell-list"}>
                {gerceklesenSatislar &&
                    <Table
                        rowKey={"id"}
                        dataSource={gerceklesenSatislar}
                        size={"small"}
                        pagination={false}
                    >

                        <Column width={175} title={"Zaman"} dataIndex={"createdDate"}></Column>
                        <Column width={120} title={"Satış Tipi"}  align={"center"} dataIndex={"satisTipi"}></Column>
                        <Column width={200} title={"İşlem Yapan"} align={"center"} dataIndex={"islemYapan"}></Column>
                        <Column width={200} render={toplamTutar => (toplamTutar.toFixed(2) + " TL")} title={`Toplam Tutar (${(gerceklesenSatislar.reduce((total, item) => total + item.toplamTutar, 0)).toFixed(2)} TL)`} align={"right"} dataIndex={"toplamTutar"}></Column>
                        <Column title={"Satış Detay"} align={"left"} dataIndex={"detail"}></Column>

                    </Table>

                }

            </div>
        </>
    )
}

export default DashboardPage